.MuiDateTimePickerToolbar-timeDigitsContainer {
    display: block !important;
}

.force-calendar-icon .MuiInputAdornment-root {
    visibility: visible !important;
}

.force-calendar-icon .MuiSvgIcon-root {
    color: currentColor !important;
}

.typography-header {
    font-family: montserrat !important;
    color: #000;
    font-weight: 600 !important;
}

.typography-title {
    font-family: montserrat !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.typography-subtitle {
    color: #9E9E9E !important;
    font-family: montserrat !important;
    font-weight: 400 !important;
    font-size: 10px !important;
}

.date-filter-style {
    background-color: white !important;
    border-radius: 5px;
    min-width: 150px !important; 
    font-size: 0.875rem !important; 
    padding: 0px !important; 
    margin-top: -8px !important;
}

.date-filter-style .MuiSvgIcon-root {
    font-size: 18px !important; 
}

.date-filter-style .MuiFormLabel-root{
    margin-top: 6px !important;
}
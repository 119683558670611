.custom {
  width: 100%;
}
.custom .css-1tcp9us-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  min-width: inherit;
  min-height: inherit;
}
div .error{
  color: red;
  font-size: 15px;
} 
/* .custom .css-135f4hd-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
} */
/* .custom .css-5oh42b-MuiDataGrid-root {
    height: auto !important;
} */
/* .custom .css-axafay-MuiDataGrid-virtualScroller {
    height: auto !important;
} */
.customLocationChip {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 10px;
    background-color: #FFFFFF !important;
    width: 9rem;
}

.customStatusChip {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 5px !important;
    width: 4rem;
    z-index: 10 !important;
}

.btnStyle {
    margin-right: 10px;
    background-color: #344767 !important;
    color: #fff !important;
    border-radius: 100px !important;
}

.duplicateBtnStyle {
    margin-right: 10px;
    background-color: #fff !important;
    color: #7d7b7b !important;
    border-radius: 100px !important;
    border-color: #7d7b7b !important;
    width: -webkit-fill-available !important;
    margin: 5px !important;
    height: 12px;
    /* justify-content: space-between !important; */
}

.deleteBtnStyle {
    margin-right: 10px;
    background-color: #fff !important;
    color: #FF0000 !important;
    border-radius: 100px !important;
    border-color: #FF0000 !important;
    width: -webkit-fill-available !important;
    margin: 5px !important;
    height: 12px;
    /* justify-content: space-between !important; */
}

.addBtnStyle {
    margin-right: 10px;
    background-color: rgb(144, 141, 141) !important;
    color: white !important;
    border-radius: 100px !important;
    border-color: #7d7b7b !important;
    width: -webkit-fill-available !important;
    height: 12px;
}